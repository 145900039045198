.app-loan {
  position: relative;
  .tab-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      color: #9d9d9d;
    }
  }
  .ant-tabs {
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-content-holder {
      position: absolute;
      top: 46px;
      height: calc(100vh - 106px);
      width: calc(100vw - 240px);
      overflow-y: scroll;
      margin-left: -32px;
      margin-right: -32px;
      padding: 32px;
    }
  }
}
