@import "/src/~@centrate-io/barn/dist/variables";

.company-container {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  position: fixed;
  top: 0px;
  right: 16px;
  z-index: 9999999;
  cursor: pointer;
  > div {
    margin: 0 !important;
  }
  .company-icon {
    width: 40px;
    height: 40px;
    background: $dark-1000;
    color: #fff;
    border-radius: 20px;
    display: flex;
    font-weight: 700;
    font-size: 18px;
    margin-right: 8px;
    min-width: 24px;
  }
}

.company-drop-drop {
  min-width: 260px !important;
  margin-left: 4px;
  padding-top: 1px;
  .drop-user-container {
    &:hover,
    &.ant-dropdown-menu-item-active {
      background-color: transparent !important;
      cursor: default !important;
    }
    .drop-user {
      padding: 8px 0;
      h5 {
        margin: 0;
        font-size: 14px;
        span {
          text-transform: capitalize;
          margin-left: 8px;
        }
      }
      p {
        margin: 0;
        color: $dark-400;
        font-size: 12px;
        line-height: 12px;
      }
      > div {
        color: $dark-700;
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        margin-bottom: 2px;
      }
    }
  }
}
