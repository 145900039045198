.app-sale {
    position: relative;
    .tab-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        color: #9d9d9d;
      }
    }
    .ant-tabs-nav {
      margin-bottom: 0 !important;
    }
    .ant-tabs.active .ant-tabs-content-holder,
    .ant-tabs.closed .ant-tabs-content-holder {
      position: absolute;
      top: 46px;
      height: calc(100vh - 106px);
      width: calc(100vw - 240px);
      overflow-y: scroll;
      padding: 32px;
    }
  }
  