@import "/src/_styleguide/variables.scss";

.import-mapping {
  .wb-headers {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ededed;
    background: #f0f4f8;
    > div {
      width: 33%;
      padding: 12px 16px;
      color: #000;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .wb-actions {
    display: flex;
    align-items: center;
    border-top: 1px solid #ededed;
    padding: 16px 24px;
    justify-content: space-between;
  }
  .wb-work {
    max-height: 400px;
    overflow-y: scroll;
    .wb-map {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ededed;
      background: #fff;
      > div {
        width: 33%;
        max-width: 33%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 12px 16px;
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          font-size: 14px !important;
        }
        b {
          font-weight: 500;
        }
      }
    }
  }
}
