@import "/src/~@centrate-io/barn/dist/variables";

.pp-tabs {
  &.ant-tabs .ant-tabs-tab {
    color: $dark-500;
  }
  &.ant-tabs .ant-tabs-tab:hover {
    color: $dark-1000;
  }
  &.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $dark-1000;
    text-shadow: 0 0 0.25px #000;
  }
  &.ant-tabs .ant-tabs-ink-bar {
    background: $dark-1000;
  }
  .tab-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
}

.pp-tabs-popup {
  .ant-tabs-dropdown-menu-item .tab-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $dark-1000;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      color: $dark-1000;
    }
  }
}
