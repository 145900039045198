@import "/src/~@centrate-io/barn/dist/variables";

textarea.pp-text-area {
  font-family: $font-major;
  outline: 0;
  padding: 12px 20px 12px 16px;
  box-shadow: none;
  border-radius: 6px;
  background: $light-1000;
  border: 2px solid transparent;
  box-shadow: 0 0 0 1px $light-800;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $light-0;
  overflow: hidden;
  &::placeholder {
    color: $light-600;
  }
  &:hover {
    border: 2px solid $light-0;
    box-shadow: none;
  }
  &:focus {
    border: 2px solid $light-0;
    background: $light-1000;
    box-shadow: none;
  }
  &[disabled] {
    border: 1px solid #f5f7fb;
    background: #f5f7fb;
    color: #adb5c5;
    &::placeholder {
      color: #adb5c5;
    }
  }
  &.pp-invalid {
    border-color: $error !important;
  }
}

.pp-text-area-wrapper {
  position: relative;
  > svg {
    position: absolute;
    left: 12px;
    z-index: 9;
    width: 24px;
    height: 24px;
    top: 15px;
  }
}
