.loan-form {
  padding: 32px;
  .lf-cat {
    margin-bottom: 32px;
    .lf-items {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      background: #fff;
      border-radius: 12px;
      border: 1px solid #ededef;
      padding: 24px;
      margin-top: 24px;
      .form-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        label {
          width: 180px;
          margin: 0 !important;
        }
      }
    }
  }
}
