.import-workbook {
  .form-workbook {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .ant-input-number .ant-input-number-handler-wrap {
      z-index: 99;
    }
  }
  .wb-actions {
    display: flex;
    align-items: center;
    border-top: 1px solid #ededed;
    padding: 16px 24px;
    justify-content: space-between;
  }
}
