@import "/src/_styleguide/variables.scss";

.import-modal {
  .ant-modal-body {
    padding: 0 !important;
    min-height: 200px;
  }
  .upload-doc {
    .ant-upload-wrapper {
      display: block;
      height: 200px;
    }
  }
}
