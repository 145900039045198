/* FONTS */
$font-title: "Neue Haas Grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
$font-header: "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
$font-main: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

/* COLORS */
$background: #f0f2f6;
$white: #ffffff;
$primary: #006295;
$secondary: #292559;
$text: #7a7a7a;
$accent: #61ce70;

/* APP COLORS */
$unknown: #e7ecf4;
$neutral-300: #d7dde9;
$call: #c1ddbb;
$linkedin: #b0d0e8;
$status: #d2d0fb;
$note: #efbba9;

/* Media Queries */
$breakpoint-extra: 1200px;
$breakpoint-large: 992px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 664px;
