@import "/src/~@centrate-io/barn/dist/variables";

.pp-select {
  width: 100%;
  &.pp-invalid {
    border-color: $error !important;
  }
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-family: "PP Mori", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    border-radius: 6px;
    color: $dark-1000;
    border: 2px solid transparent;
    box-shadow: 0 0 0 1px #ebebea;
    outline: none !important;
  }
  &:hover .ant-select-selector,
  &.ant-select-focused .ant-select-selector {
    border-color: $dark-1000 !important;
    box-shadow: none !important;
  }
  &.pp-large {
    // padding: 0 16px;
    height: 56px;
    // line-height: 56px;
    .ant-select-selector {
      border-radius: 12px !important;
    }
  }
  &.pp-medium {
    // padding: 0 16px;
    height: 40px;
    // line-height: 56px;
    .ant-select-selector {
      border-radius: 6px !important;
    }
  }
  &.pp-average .ant-select-selector > span {
    font-size: 14px !important;
  }
  &.pp-extra-medium {
    // padding: 0 16px;
    height: 48px;
    // line-height: 56px;
    .ant-select-selector {
      border-radius: 10px !important;
    }
  }
  &.ant-select .ant-select-selector > span {
    font-size: 16px;
  }
  &.ant-select.ant-select-disabled .ant-select-selector,
  &.ant-select.ant-select-disabled:hover .ant-select-selector {
    border: 1px solid rgb(245, 247, 251) !important;
    border-color: rgb(245, 247, 251) !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #000 !important;
}

.pp-select-dropdown {
  &.ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #a2d683;
    color: #000;
  }
}
