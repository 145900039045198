@import "/src/~@centrate-io/barn/dist/variables";

.pp-input-label {
  &.pp-invalid {
    color: $error !important;
  }
  > span {
    float: right;
    font-weight: 400;
    color: #757575;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
      position: relative;
      top: -1px;
    }
  }
}
