@import "/src/~@centrate-io/barn/dist/variables";

// input.barn-input {

//   &::placeholder {
//     color: $light-600;
//   }
//   &:hover {
//     border: 2px solid $light-0;
//     box-shadow:none;
//   }
//   &:focus {
//     border: 2px solid $light-0;
//     background: $light-1000;
//     box-shadow:none;
//   }
//   &[disabled] {
//     border: 1px solid #f5f7fb;
//     background: #f5f7fb;
//     color: #adb5c5;
//     &::placeholder {
//       color: #adb5c5;
//     }
//   }
// }

.pp-input-number {
  &.pp-invalid {
    border-color: $error !important;
  }
  &.ant-input-number {
    width: 100%;
    height: 48px;
    line-height: 48px;
    outline: 0;
    box-shadow: none;
    border-radius: 6px;
    background: $light-1000;
    border: 2px solid transparent;
    box-shadow: 0 0 0 1px $light-800;
    overflow: hidden;
    border-radius: 10px;
  }
  &.ant-input-number input {
    font-family: $font-major;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: $light-0;
  }
  &.ant-input-number .ant-input-number-input-wrap {
    position: relative;
    top: -2px;
  }
  &.ant-input-number input::placeholder {
    color: $light-600;
  }
  &.ant-input-number:hover {
    border: 2px solid $light-0;
    box-shadow: none;
  }
  &.ant-input-number:focus {
    border: 2px solid $light-0;
    background: $light-1000;
    box-shadow: none;
  }
  &.ant-input-number.ant-input-number-disabled {
    border: 1px solid #f5f7fb;
    background-color: rgba(0, 0, 0, 0.04) !important;
    color: #adb5c5;
    .ant-input-number-input-wrap input::placeholder,
    input {
      color: #adb5c5;
    }
  }
  &.pp-small {
    padding: 0 8px;
    padding-left: 0 !important;
    height: 32px;
    line-height: 32px;
    border-radius: 8px;
    input {
      font-size: 14px;
    }
  }
  position: relative;
  top: 2px;
}

.pp-input-number-wrapper {
  position: relative;
  > svg {
    position: absolute;
    left: 12px;
    z-index: 9;
    width: 24px;
    height: 24px;
    top: 15px;
  }
  &.pp-icon input {
    padding-left: 48px !important;
  }
  // input.pp-medium {
  //   padding: 0 12px;
  //   height: 48px;
  //   line-height: 48px;
  //   border-radius: 10px;
  // }
}
