.auth-box {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  background: #fafaf9;
  .barn-gradient {
    position: fixed;
    left: 0;
    top: 0;
  }
  .flip-page {
    position: fixed;
    top: 38px;
    right: 32px;
  }
  .dark-toggle {
    position: fixed;
    left: 16px;
    bottom: 16px;
  }
  .auth-logo {
    position: fixed;
    top: 44px;
    left: 50%;
    transform: translateX(-50%);
  }
}
