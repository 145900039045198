@import "/src/_styleguide/variables.scss";

.pp-tag {
  color: #000;
  font-family: $font-title;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 8px;
  &.pp-tag-small {
    line-height: 16px;
    font-size: 12px;
  }
  &.pp-tag-success {
    background: #a2d683;
    border-color: #a2d683;
    color: #000;
  }
  &.pp-tag-neutral {
    background: #ececec;
    border-color: #ececec;
    color: #000;
  }
  &.pp-tag-primary {
    background: #000;
    border-color: #000;
    color: #fff;
  }
  &.pp-tag-warning {
    background: #fcefbf;
    border-color: #fcefbf;
    color: #000;
  }
  &.pp-tag-danger {
    background: #f9d2ce;
    border-color: #f9d2ce;
    color: #000;
  }

  /* Roles */
  &.pp-tag-role {
    line-height: 24px;
    border-radius: 24px;
    padding: 0 8px;
  }
  &.pp-tag-super {
    background: #f2fdeb;
    border-color: #a2d683;
    color: #2b600a;
  }
  &.pp-tag-admin {
    background: #e3f7fd;
    border-color: #a1d3e2;
    color: #0a4b60;
  }
  &.pp-tag-manager {
    background: #f4e6fd;
    border-color: #d7c0e5;
    color: #6f07b0;
  }
  &.pp-tag-user {
    background: #fcede4;
    border-color: #f2c3a8;
    color: #963c07;
  }
  &.pp-tag-removed {
    background: #f9d2ce;
    border-color: #f9d2ce;
    color: #000;
  }
}
