@font-face {
  font-family: "Menlo Regular";
  font-style: normal;
  font-weight: normal;
  src:
    local("Menlo Regular"),
    url("Menlo-Regular.woff") format("woff");
}

@font-face {
  font-family: "Neue Haas Grotesk";
  src: url("NeueHaasGrotesk/NeueHaasDisplayLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas Grotesk";
  src: url("NeueHaasGrotesk/NeueHaasDisplayLightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Grotesk";
  src: url("NeueHaasGrotesk/NeueHaasDisplayRoman.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas Grotesk";
  src: url("NeueHaasGrotesk/NeueHaasDisplayRomanItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Grotesk";
  src: url("NeueHaasGrotesk/NeueHaasDisplayMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas Grotesk";
  src: url("NeueHaasGrotesk/NeueHaasDisplayMediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Grotesk";
  src: url("NeueHaasGrotesk/NeueHaasDisplayBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas Grotesk";
  src: url("NeueHaasGrotesk/NeueHaasDisplayBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Grotesk";
  src: url("NeueHaasGrotesk/NeueHaasDisplayBlack.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas Grotesk";
  src: url("NeueHaasGrotesk/NeueHaasDisplayBlackItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
