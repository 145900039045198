.loans-tab {
  padding: 32px;
  .loans-table {
    .application-title {
      cursor: pointer;
      &:hover .doc-name {
        text-decoration: underline;
      }
    }
  }
}
