@import "/src/_styleguide/variables.scss";

.pp-avatar-name {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #000;
  user-select: none;
  font-family: $font-title;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  &.pp-size-small {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    font-size: 14px;
  }
  &.pp-size-tiny {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    font-size: 11px;
  }
}
